import React from 'react'
import { gql } from '@apollo/client'
import { HomepageQuery } from './__generated__'
import {
  Banner,
  BANNER_FRAGMENT,
  COLUMN_FEATURE_FRAGMENT,
  ColumnFeature,
  FULL_SCREEN_CTA_FRAGMENT,
  FullScreenCta,
  News, NEWS_FRAGMENT,
  Offers,
  OFFERS_FRAGMENT,
  QUICK_LINKS_FRAGMENT,
  QuickLinks,
  RELATED_ARTICLES_FRAGMENT,
  RelatedArticles,
  SIMPLE_CTA_FRAGMENT,
  SimpleCta,
} from './fragments'
import { Container, Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { rem } from '../../utils/rem'
import { Contained } from '../../components/contained/contained'
import { nonNull } from '../../utils/nonNull'
import { IMAGE_FRAGMENT } from '../../fragments/image'

export type HomeProps = HomepageQuery & {
  //
}

const slantedStyles = css`
  margin-top: ${props => rem(40, props.theme)};
  padding-top: ${props => rem(100, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => rem(120, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => rem(150, props.theme)};
  }

  &::before {
    bottom: ${props => rem(20, props.theme)};
    clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 calc(100% - 80px));

    ${props => props.theme.breakpoints.up('sm')} {
      clip-path: polygon(0 80px, 100% 0, 100% 100%, 0 calc(100% - 120px));
    }

    ${props => props.theme.breakpoints.up('md')} {
      top: ${props => rem(-50, props.theme)};
      clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 calc(100% - 100px));
    }

    ${props => props.theme.breakpoints.up('lg')} {
      top: ${props => rem(-70, props.theme)};
      clip-path: polygon(0 125px, 100% 0, 100% 100%, 0 calc(100% - 125px));
    }

    ${props => props.theme.breakpoints.up('xl')} {
      top: ${props => rem(-90, props.theme)};
      clip-path: polygon(0 155px, 100% 0, 100% 100%, 0 calc(100% - 155px));
    }
  }
`

const flatStyles = css`
  margin: ${props => rem(64, props.theme)} 0 ${props => rem(85, props.theme)};
  padding: ${props => rem(71, props.theme)} 0 ${props => rem(64, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${props => rem(110, props.theme)} 0 ${props => rem(157, props.theme)};
    padding: ${props => rem(190, props.theme)} 0 ${props => rem(157, props.theme)};
  }
`

export const StyledAltBgSection = styled(Container)<{ $slanted?: boolean }>`
  position: relative;

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    opacity: 0.5;
    position: absolute;
    z-index: -1;
  }

  ${props => props.$slanted ? slantedStyles : flatStyles}
`

const StyledSimpleCtaWrapper = styled.div`
  margin-top: ${props => rem(70, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(60, props.theme)};
  }
`

const StyledFullScreenCtaContainer = styled(Grid)`
  margin-top: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(83, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => rem(157, props.theme)};
  }
`


const StyledHomeContainer = styled(Container)`
  margin-bottom: ${props => props.theme.typography.pxToRem(102)};
  padding: 0;

  .MuiContainer-root {
    padding-left: ${props => props.theme.typography.pxToRem(10)};
    padding-right: ${props => props.theme.typography.pxToRem(10)};
  }

  > .home-promotion {
    padding: 0;
    margin-top: ${props => props.theme.typography.pxToRem(115)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(190)};
    }

    > .MuiContainer-root {
      padding-left: ${props => props.theme.typography.pxToRem(0)};
      padding-right: ${props => props.theme.typography.pxToRem(0)};
    }

    > .MuiContainer-maxWidthLg {
      padding-top: ${props => props.theme.typography.pxToRem(64)};

      ${props => props.theme.breakpoints.up('sm')} {
        padding-top: ${props => props.theme.typography.pxToRem(108)};
      }
    }
  }
`

const StyledCardCarouselContainer = styled(Container)`
  padding: 0;
  margin-top: ${props => props.theme.typography.pxToRem(93)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(154)};
  }
`

export const Home: React.FC<HomeProps> = ({ entry, entries }) => {
  // We know this is always a homepage, but technically GraphQL says this could be any entry, so we must first check
  if (!entry || entry.__typename !== 'homePage_homePage_Entry') {
    return null
  }

  return (
    <StyledHomeContainer maxWidth={false}>      
      {entry.bannerHome && (
        <Container> 
          <Contained container lg={10} justifyContent="center">
            <Grid item lg={12}>
              <Banner data={nonNull(entry.bannerHome)} />
            </Grid>            
          </Contained>
        </Container>
      )}
      <StyledAltBgSection maxWidth={false} $slanted>
        <Container>
          <Contained container lg={10} justifyContent="center">
            {/** @ts-ignore **/}
            {entry.homePageQuickLinks && (
              <Grid item lg={12}>
                <QuickLinks data={nonNull(entry.homePageQuickLinks)} />
              </Grid>
            )}
          </Contained>
        </Container>
        {entry.simpleCta &&
          <StyledSimpleCtaWrapper>
            <SimpleCta data={nonNull(entry.simpleCta)} />
          </StyledSimpleCtaWrapper>
        }
      </StyledAltBgSection>
      {entry.relatedArticles && (
        <StyledCardCarouselContainer maxWidth={'lg'}>
          <RelatedArticles data={nonNull(entry.relatedArticles)} />
        </StyledCardCarouselContainer>
      )}
      <StyledAltBgSection maxWidth={false} className="home-promotion">
        {entry.offersPromotions && (
          <Container>
            <Grid item lg={12}>
              <Offers data={nonNull(entry.offersPromotions)} />
            </Grid>
          </Container>
        )}
        {entry.fullScreenCta && (
          <>
            <StyledFullScreenCtaContainer>
              <FullScreenCta data={nonNull(entry.fullScreenCta)} />
            </StyledFullScreenCtaContainer>
          </>
        )}
        {entry.columnFeature && (
          <Container>
            <Contained container>
              <Grid item lg={10}>
                <ColumnFeature data={nonNull(entry.columnFeature)} />
              </Grid>
            </Contained>
          </Container>
        )}
      </StyledAltBgSection>
      {entries && (
        <StyledCardCarouselContainer maxWidth={'lg'}>
          <News entries={nonNull(entries)} />
        </StyledCardCarouselContainer>
      )}
    </StyledHomeContainer>
  )
}

export const HOMEPAGE_QUERY = gql`
  ${BANNER_FRAGMENT}
  ${QUICK_LINKS_FRAGMENT}
  ${SIMPLE_CTA_FRAGMENT}
  ${RELATED_ARTICLES_FRAGMENT}
  ${OFFERS_FRAGMENT}
  ${FULL_SCREEN_CTA_FRAGMENT}
  ${COLUMN_FEATURE_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${NEWS_FRAGMENT}
  
  query HomepageQuery($uid: [String]) {
    entry(section: "homePage", uid: $uid) {
      ... on homePage_homePage_Entry {
        bannerHome {
          ...BannerFragment
        }
        homePageQuickLinks {
          ...QuickLinksFragment
        }
        simpleCta {
          ...SimpleCtaFragment
        }
        relatedArticles {
          ...RelatedArticlesHomeFragment
        }
        offersPromotions {
          ...OffersFragment
        }
        fullScreenCta {
          ...FullScreenCtaFragment
        }
        columnFeature {
          ...ColumnFeatureFragment
        }
      }
    }
    ...NewsFragment
  }
`
export { StyledHeading } from './styledHeading'
