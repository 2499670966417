import React from 'react'
import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { OffersFragment } from './__generated__/offers'
import { StyledHeading } from '../styledHeading'
import { OfferPromotionCarousel } from '../../../components/OfferPromotionCarousel/OfferPromotionCarousel'

export type OffersProps = {
  data: OffersFragment[]
}

export const Offers: React.FC<OffersProps> = ({ data }) => {
  return (
    <>
      <StyledHeading variant="h5" align="center">
        Latest Promotions
      </StyledHeading>
      <OfferPromotionCarousel>
        {
          data.map((item, index) => (
            item.__typename !== 'offersPromotions_BlockType' ? null :
              <OfferPromotionCarousel flickitySection key={index} variant={ index <= 3 ? 'offer' : 'promotion'}>
                {item?.carouselItemImage?.[0]?.url && (
                  <OfferPromotionCarousel image src={item.carouselItemImage[0].url} alt="" layout="fill" objectFit="cover" objectPosition="center" />
                )}
                <OfferPromotionCarousel contentSection>
                  <OfferPromotionCarousel title>{item.carouselItemTitle}</OfferPromotionCarousel>
                  {item.carouselItemSubtext && <OfferPromotionCarousel description>{item.carouselItemSubtext}</OfferPromotionCarousel>}
                  {item.carouselButtonText && <OfferPromotionCarousel href={item?.carouselButtonLink?.url || '#'} cta primary buttonColor="black">{item.carouselButtonText}</OfferPromotionCarousel>}
                </OfferPromotionCarousel>
              </OfferPromotionCarousel>
          ))
        }
      </OfferPromotionCarousel>
    </>
  )
}

export const OFFERS_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment OffersFragment on offersPromotions_BlockType {
    id
    carouselItemTitle
    carouselItemSubtext
    carouselButtonText
    carouselButtonLink {
      url
      target
    }
    carouselItemImage {
      ...ImageFragment
    }
  }
`